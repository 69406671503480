<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row dense>
        <v-col xs="12" sm="12" md="6" lg="4">
          <organisationComponent class="mb-5" />
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="8">
          <v-row dense>
            <v-col xs="12" sm="12" md="12" lg="6">
              <informationComponent class="mb-5" />
            </v-col>
            <v-col xs="12" sm="12" md="12" lg="6">
              <scheduleComponent :hours="entity.hours" :entityId="entity.id" :hoursReversed="entity.hours_text2" class="mb-5" />
            </v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import organisationComponent from "@/modules/entities/entitiesComponents/organisationComponent";
import informationComponent from "@/modules/entities/entitiesComponents/informationComponent";
import scheduleComponent from "@/modules/entities/entitiesComponents/scheduleComponent";

export default {
  name: "EntityDetailDashboard",
  components: {
    organisationComponent,
    informationComponent,
    scheduleComponent,
  },
  data() {
    return {
      form:{}
    };
  },
  created() {},
  methods: {
    // refreshData:function(){
    //   this.$emit('refreshData')
    // }
  },
  computed: {
    entity: function(){
      return this.$store.state.entities.entity
    },
    // packId(){
    //   if(this.$store.state.auth.currentUser.accountParameters.packId)
    //     return this.$store.state.auth.currentUser.accountParameters.packId
    //   else 
    //     return false
    // }
  }
};
</script>
<style lang="scss">
</style>
