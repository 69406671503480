<template>
    <v-card rounded="lg" class="px-1 pt-2">
        <h2 class="mb-2 mx-4">{{ $t('SelectedTemplates') }}</h2>
        <v-card-text class="">
            <div v-if="mission.config.selected_templates && mission.config.selected_templates.length > 0">
                <v-carousel v-model="model" hide-delimiters  height="400" :show-arrows="mission.config.selected_templates.length == 1 ? false : true">
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text color="lightgrey" fab>
                            <v-icon text color="darkgrey">$prev</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text color="grey" fab>
                            <v-icon text color="darkgrey">$next</v-icon>
                        </v-btn>
                    </template>
                    <v-carousel-item v-for="(media, i) in mission.config.selected_templates" :key="'media_'+i">
                        <div style="height:100%;" class="d-flex justify-center align-center">
                            <v-img :src="media.url" v-if="media.mime !== -1 && media.mime != 'video/mp4'" height="100%" contain style="z-index:10"></v-img>
                            <video controls width="100%" v-else>
                                <source :src="media.url" :type="media.mime" />
                            </video>
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <v-alert v-else border='left' :icon="'$warning_s'" type="info" text class="mb-0 mt-3">
                {{$t('noMediaAvailable')}}
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "templateAcquisitionsComponent",
    mixins: [dateManipulation],
    props: ['mission'],
    data() {
        return {
            model: 0
        }
    },
    created() {},
    mounted() {},
    watch: {},
    methods: {}
}
</script>